import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
  Spinner,
} from "react-bootstrap";
import Confetti from "react-dom-confetti";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [url, setUrl] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confetti, setConfetti] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);
    setConfetti(false);

    try {
      const response = await fetch(`https://tools-api.ballerine.app/scrape-kyb`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });

      if (!response.ok) {
        throw new Error("An error occurred while fetching KYB data.");
      }

      const data = await response.json();
      setResult(data);
      setConfetti(true);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const confettiConfig = {
    angle: 90,
    spread: 100,
    startVelocity: 45,
    elementCount: 50,
    dragFriction: 0.1,
    duration: 3000,
    stagger: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  return (
    <Container className="App mt-5">
      <Row>
        <Col>
          <h1 className="text-center">KYB Data Extractor</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="url">
              <Form.Label>Company Website URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter the company's website URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
              />
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ml-2">  Extracting...</span>
                  </>
                ) : (
                  "Extract KYB Data"
                )}
              </Button>
              <Confetti active={confetti} config={confettiConfig} />
            </div>
          </Form>
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          {result && (
            <Card className="mt-3">
              <Card.Header>KYB Data</Card.Header>
              <Card.Body>
                <pre>{JSON.stringify(result, null, 2)}</pre>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
